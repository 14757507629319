@import "./variables";

* {
  box-sizing: border-box;
}

html {
  height: 100%;
}

@font-face {
  font-family: "Source Sans Pro", sans-serif;
  src: url(/assets/fonts/SourceSansPro-Regular.ttf) format("truetype");
}

@font-face {
  font-family: "Source Sans Pro", sans-serif;
  src: url(/assets/fonts/SourceSansPro-Bold.ttf) format("truetype");
}

body {
  font-family: "Montserrat", sans-serif;
  margin: 0;
  padding: 0;
  min-height: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

::placeholder {
  font: normal normal normal 17px/20px Montserrat;
  color: #9e9e9e;
}



input {
  border-radius: 8px;
  border-style: none;
}

.p-button {
  cursor: pointer;
}
.p-checkbox .p-checkbox-box {
  border-radius: 0;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: $color-primary;
  background: $color-primary;
}

.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  box-shadow: none;
  border-color: $color-primary;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
  background: $color-black;
  border-color: $color-black;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled).p-focus {
  box-shadow: none;
}

.p-radiobutton .p-radiobutton-box:not(.p-disabled):not(.p-highlight):hover {
  border-color: $color-black;
}

.p-dialog {
  border-radius: 0.5rem;
  height: auto;

  .p-dialog-header {
    border-top-right-radius: 0.5rem;
    border-top-left-radius: 0.5rem;
  }

  .p-dialog-content {
    font: normal normal bold 21px/26px Montserrat;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: baseline;
    color: #707070;

    &:last-of-type {
      border-bottom-right-radius: 0.5rem;
      border-bottom-left-radius: 0.5rem;
    }
  }
}

.p-carousel .p-carousel-content .p-carousel-prev,
.p-carousel .p-carousel-content .p-carousel-next {
  border: 2px solid;
  color: #4c4892;
}

.p-dialog-mask.p-component-overlay {
  backdrop-filter: blur(4px);
}

.p-inputtext::placeholder {
  color: #9e9e9e; 
}

.p-inputtext:focus::placeholder {
  color: #000000; 
}


input.ng-valid, .p-inputmask:not(:placeholder-shown){
  color: black;
}

input.ng-dirty, .ng-touched, .p-inputtext:enabled.ng-touched{
  color:$color-black;
}

.p-inputtext:disabled, input:disabled{
  opacity: 1;
  background-color: #d9d9d9;
  color: #64748b;
}

input:focus{
  outline: none;
}

inputmask{
  color: black
}