.text-title{
    font-family: Montserrat;
    font-weight: 400;
    font-size: 1.5rem;
    color: $color-primary;
}

label, .formgrid .field> label{
    font: normal normal normal 18px/30px Montserrat;
    color: $color-black;
}

h5{
    font: normal normal bold 18px/20px Montserrat;
    color: #7670B3;
}

