$color-primary: #7670B3;
$color-white: #FFFFFF;
$third-color: #78CDD1;
$color-pink: #C77EB5;
$color-button: #4C4892;
$bg-gray: #ECEEF1;
$color-black: #000000;
$color-text-medium: #38404B;
$color-text-paragraph: #3C3E44;

$yellow-green: #BFCD31;